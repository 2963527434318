











































































































import { Component, PropSync, Ref, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { ServiceUpsertModel } from "@/api/generated";
import CountryAutocomplete from "@/components/common/country/CountryAutocomplete.vue";
import UserModule from "@/store/userModule";
import moment from "moment";
import momentUtility from "@/utility/momentUtility";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: {
    CountryAutocomplete
  }
})
export default class ServiceEdit extends Validation {
  @Ref() private form!: VForm;
  @Prop(String) serviceId!: string;

  private maxDate: moment.Moment = moment();
  private loading = false;
  private model: ServiceUpsertModel = {
    companyName: ""
  };

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    const response = await Api.ServiceService.apiServiceServiceIdGet(
      this.serviceId
    );

    this.model = response.data;
    if (this.model.startDate) {
      this.model.startDate = momentUtility.pickerFormat(this.model.startDate);
    }
  }

  private async onServiceUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.ServiceService.apiServiceServiceIdPut(
        this.serviceId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Service updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update service");
    } finally {
      this.loading = false;
    }
  }

  private goBack() {
    this.$router.push({ name: "Service Overview" });
  }
}
